import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosure from "@tightrope/ctadisclosure"

const prdbestData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
      <Helmet>

        <style type="text/css">{`
        body {
          background-color: #e5e5e5;
        }
        h1 {
          margin: 0 auto !important;
        }
        .trbm-module--logo {
          margin: 0px 0px 10px !important;
          width: 130px !important;
        }

        .trbm-module--trbm .trbm-module--steps {
          display: none;
        }

        .trbm-module--trbm button {
          padding: 20px 60px!important;
          background: #43ba1a;
          transition: .2s ease;
        }

        .trbm-module--card {
          padding: 20px 20px 30px;
        }

        #ctadisclosuresearch-module--ctadisclaimersearch {
          padding: 0px 7%;
          padding-bottom: 0;
        }

        .trbm-module--trbm h2 {
          margin-top: 7px;
        }

        .trbm-module--trbm .trbm-module--bullets {
          padding-left: 65px;
          list-style: circle;
          color: #333;
          font-weight: lighter;
        }

        #ctadisclosure-module--ctadisclaimer {
            padding: 25px 7% 0;
          }

      `}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <Trbm data={prdbestData}><CtaDisclosure data={prdbestData}></CtaDisclosure></Trbm>
    </HomepageLayout>
  )
}
